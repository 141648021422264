export const environment = {
  production: false,

  appVersion: require('../../package.json').version,


  serviceUrls: {
    application : 'https://konect-api-dev.azurewebsites.net/api/v1',
    roi         : 'https://konect-flask-api-dev.azurewebsites.net/api/v1',
  },

  msalConfig: {
    auth: {
      clientId: '7cb48a25-a9e5-4fc7-b707-15e3157a4ea3',
      authority:
        'https://login.microsoftonline.com/8df22a8d-9153-489d-950c-a20cccb65620',
      redirectUri: 'https://konect-dev.vontierdatadev.com',
    },
  },

  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};
